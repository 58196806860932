// import Flickity from 'flickity';
// import Overlay from '../util/overlay';
// import GMenu from "../components/g-menu";
// import Scroller from '../util/scroller';
// import ScrollMagic from 'scrollmagic';
// import objectFitImages from 'object-fit-images';

// import 'bootstrap/js/src/alert';
// import 'bootstrap/js/src/util';

function callbackVisible() {
    this.classList.add('is-visible');
}

function callbackSlideY() {
    this.classList.add('slide-y');
    // const animate = () => {
    //     const tick = () => {
    //         const progress = getProgress(animation);
    //         if (progress < 1) requestAnimationFrame(tick);

    //         // 1. write your animation logic -----------------------------------------
    //         const easing = ease.out.circular(progress);
    //         const value = getCurrentValue({from: 0, to: 800, easing});
    //         this.style.transform = `translate(${value}px)`;
    //     };

    //     const animation = {
    //         // 2. optionally specify a duration (defaults to 1s) ---------------------
    //         duration: 2000,
    //         id: requestAnimationFrame(tick)
    //     };
    // };
}


export default {
  init() {
    // new GMenu();
    // new SearchOverlay();
    // make alerts dismissable (newsletter signup forms, contact form)
    // $('.alert').alert();

    this.productTiles = document.querySelectorAll(".product-tile");

    this.elementsToFadeIn = document.querySelectorAll(".fade-in");
  },
  finalize() {

    // objectFitImages('img.object-fit-cover');

    // var banner;
    // var overlays = document.querySelectorAll('.overlay');
    // var overlay_id;
    // var overlay;

    // if (overlays !== null) {
    //     for (var i = 0; i < overlays.length; i++) {
    //         overlay_id = overlays[i].id;
    //         overlay = new Overlay({
    //           trigger: document.querySelectorAll('.overlay__button--' + overlay_id),
    //           events: {
    //             beforeClose: function() {
    //               var videoIframe = document.getElementById(overlay_id+"iframe");
    //               // var iframeWindow = videoIframe.contentWindow ? videoIframe.contentWindow : videoIframe.contentDocument.defaultView;
    //               //     iframeWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    //               //jQuery("#"+overlay_id+"iframe")[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    //                   // videoIframe.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    //               // var iframe = element.querySelector("iframe");
    //               var video = videoIframe.querySelector('video');

    //               if (videoIframe) {
    //                   var iframeSrc = videoIframe.src;
    //                   videoIframe.src = iframeSrc;
    //               }

    //               if (video) {
    //                 video.pause();
    //               }
    //             }
    //           }
    //         });
    //     }
    // }

      // ScrollMagic animations
      var mobileMenu = document.getElementById("gn-menu-main");
      var isMobile = !!(mobileMenu.offsetWidth || mobileMenu.offsetHeight || mobileMenu.getClientRects().length);

      var dropShadowMultiplier = (isMobile) ? 10 : 15 ;

      var scrollController = new ScrollMagic.Controller();

      // Instagram feed block
      var instagramRow = document.getElementById("instagram-row");
      if (instagramRow) {
        if (!isMobile && instagramRow.childNodes.length) {
            var instaItems = document.querySelectorAll('.instagram-col:nth-child(2n + 1)');
            var instaScene = new ScrollMagic.Scene({
                triggerElement: '#instagram-block',
                duration: Math.round(window.innerWidth / 2),
                offset: -300
            })
            .on('progress', function(event) {
                for (var i = 0; i < instaItems.length; i++) {
                  instaItems[i].style.transform = 'translateY(' + event.progress * 20 +'%)';
                }
            })
            .addTo(scrollController);
        }
      }

          // for (var i = 0; i < rightDropShadows.length; i++) {
          //   rightDropShadows[i].style.transform = 'translate(+' + event.progress * 7 +'%, +' + event.progress * 7 + '%)';
          // }

      // Drop-shadow scroll animation
      var leftImgRightText = document.querySelectorAll('.left-img-right-text');

      if (leftImgRightText.length) {
        for (var i = 0; i < leftImgRightText.length; i++) {
          var leftDropShadows = leftImgRightText[i].querySelectorAll('.drop-shadow-left .drop-shadow');
          new ScrollMagic.Scene({
            triggerElement: leftImgRightText[i],
            duration: window.innerHeight,
            offset: -300
          })
          .on('progress', function(event) {
            for (var i = 0; i < leftDropShadows.length; i++) {
              leftDropShadows[i].style.transform = 'translate(-' + event.progress * dropShadowMultiplier +'%, -' + event.progress * dropShadowMultiplier + '%)';
            }
          })
          .addTo(scrollController);
        }
      }

      var rightImgLeftText = document.querySelectorAll('.right-img-left-text');

      if (rightImgLeftText.length) {
        for (var i = 0; i < rightImgLeftText.length; i++) {
          var rightDropShadows = rightImgLeftText[i].querySelectorAll('.drop-shadow-right .drop-shadow');
          new ScrollMagic.Scene({
            triggerElement: rightImgLeftText[i],
            duration: window.innerHeight,
            offset: -300
          })
          .on('progress', function(event) {
            for (var i = 0; i < rightDropShadows.length; i++) {
              rightDropShadows[i].style.transform = 'translate(+' + event.progress * dropShadowMultiplier +'%, -' + event.progress * dropShadowMultiplier + '%)';
            }
          })
          .addTo(scrollController);
        }
      }
  }
}
