import Scroller from '../util/scroller';
// import ScrollMagic from 'scrollmagic';

function callbackVisible() {
  this.classList.add('is-visible');
  console.log("visible!");
}

function callbackSlideY() {
  this.classList.add('slide-y');
  console.log("slidey!");
}

export default {
  init() {
    var propertiesImgWrapper = document.getElementById("properties-img-wrapper");
    if (propertiesImgWrapper !== null) {
      propertiesImgWrapper.scroll = new Scroller(propertiesImgWrapper, {
        callback: callbackVisible,
        trigger: "top",
        triggerOffset: 100,
        // triggerOffsetPercentage: 25,
        once: true
      });
    }

    var interactImgWrapper = document.getElementById("interact-img-wrapper");
    if (interactImgWrapper !== null) {
      interactImgWrapper.scrollVisible = new Scroller(interactImgWrapper, {
        callback: callbackVisible,
        trigger: "center",
        triggerOffset: 0,
        // triggerOffsetPercentage: 25,
        once: true
      });

      interactImgWrapper.scrollSlide = new Scroller(interactImgWrapper, {
        callback: callbackSlideY,
        trigger: "center",
        triggerOffset: 0,
        //triggerOffsetPercentage: 100,
        once: true
      });
    }

  },
  finalize() {
  }
}
